.specificreporttable {
    /* min-width: 650; */
    width: 50% !important;
}

.outertable {
    /* min-width: 650; */
    width: 100% !important;
}

.datatable {
    width: 50% !important;
}

.specificreportcell {
    border-style: solid;
    border-width: 1px;
    text-align: left;
}